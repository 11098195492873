import { Box } from '@mui/material';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import PaymentModal from './components/PaymentModal';
import { useEffect } from 'react';
import { trackEvent } from '@api/requests';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';

function Subscription() {
  const { userLocation } = useQuizState();

  useEffect(() => {
    window.scrollTo(0, 0);

    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'SubsShown');
    }
    trackEvent('sub screen shown', userLocation);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#FCF4EF',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Header />
      <MainContent />
      <Footer />
      <PaymentModal />
    </Box>
  );
}

export default Subscription;
