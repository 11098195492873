export const APP_CONSTANTS = {
  EMAIL: 'support@refuse.live',
  ADDRESS: 'Room 1002, 10/F, Easey Commercial Building, 253-261 Hennessy Road, Wan Chai, HK Tel: 00852-37854100, Fax: 00852-37854101. BRN No.: 76614857',
  COMPANY_NAME: 'BE-WILL LIMITED',
  APP_NAME: 'Refuse',
  APP_SHORT_NAME: 'Refuse',
  WEBSITE_NAME: 'Track beat',
  IOS_DOWNLOAD_APP_LINK:
    'https://apps.apple.com/pt/app/heart-rate-monitor/id1591295637?l=en-GB',
  ANDROID_DOWNLOAD_APP_LINK:
    'https://apps.apple.com/pt/app/heart-rate-monitor/id1591295637?l=en-GB',
} as const;

export const APP_URLS = {
  APP_BASE_PATH: 'https://refuse.live/refuse/',
  DEV_APP_BASE_PATH: 'https://dev.refuse.live/refuse/',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
