import { Box, Link } from '@mui/material';
import { Routes } from '../../../../App';

function TermsAndConditions({ isMainScreen }: { isMainScreen?: boolean }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: '15px',
        '& .link': {
          fontFamily: 'Avenir400',
          fontSize: '14px',
          color: isMainScreen ? '#728879' : '#282624',
          textDecorationColor: isMainScreen ? '#728879' : '#282624',
          transition: 'all 0.3s ease',
          whiteSpace: 'nowrap',
          textAlign: isMainScreen ? 'center' : undefined,
          '&:hover': {
            opacity: 0.8,
          },
        },
      }}
    >
      <Link target="_blank" href={Routes.TERMS} className="link">
        Terms and Conditions
      </Link>
      <Link target="_blank" href={Routes.PRIVACY} className="link">
        Privacy Policy
      </Link>
      <Link target="_blank" href={Routes.COOKIE_POLICY} className="link">
        Cookie Policy
      </Link>
      <Link href={'mailto:support@refuse.com'} className="link">
        support@refuse.com
      </Link>
    </Box>
  );
}

export default TermsAndConditions;
