import { Box, Typography } from '@mui/material';
import phone1 from '../../../assets/images/main/phone_1.webp';
import phone2 from '../../../assets/images/main/phone_2.webp';
import phone3 from '../../../assets/images/main/phone_3.webp';

function Program() {
  const arr = [
    { title: 'Daily tasks', subtitle: 'Every day new and exciting', image: phone1 },
    { title: 'In-Depth Courses', subtitle: 'Developed with health experts', image: phone2 },
    { title: 'Alcohol tracker', subtitle: 'Monitor your progress', image: phone3 },
  ];
  return (
    <Box
      sx={{
        backgroundColor: '#FCF4EF',
        padding: '60px 20px',
      }}
    >
      <Typography
        id="programAnchor"
        sx={{
          scrollMargin: '84px',
          textAlign: 'center',
          fontFamily: 'Avenir500',
          fontSize: '16px',
          color: '#ED744E',
          lineHeight: 'normal',
        }}
      >
        Your Journey Begins Here
      </Typography>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir600',
          fontSize: '32px',
          color: '#282624',
          lineHeight: 'normal',
          margin: '15px 0',
        }}
      >
        Neuroscience-Based Alcohol Reduction Program
      </Typography>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir400',
          fontSize: '20px',
          color: '#908C89',
          lineHeight: 'normal',
        }}
      >
        At the core of Refuse is our unique program, crafted by top experts in mental health and medicine. Check your
        Daily Tasks to discover something new each day.
      </Typography>
      {arr.map((el) => (
        <Box
          key={el.title}
          sx={{
            marginTop: '40px',
          }}
        >
          <Box
            sx={{
              padding: '0 45px',
              '& img': {
                width: '100%',
              },
            }}
          >
            <img src={el.image} alt="program"></img>
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Avenir600',
              fontSize: '24px',
              color: '#282624',
              lineHeight: 'normal',
              marginTop: '20px',
              marginBottom: '2px',
            }}
          >
            {el.title}
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Avenir400',
              fontSize: '16px',
              color: '#908C89',
              lineHeight: 'normal',
            }}
          >
            {el.subtitle}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default Program;
