import QuizButton, { ButtonType } from '@components/common/QuizButton';
import { usePaymentState } from '@providers/PaymentProvider/PaymentProvider.hooks';
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import { APP_CONSTANTS } from '@utils/constants';
import { useUser } from '@providers/UserProvider';
import { getAttribution } from '@utils/attribution';
import { mergeSearchParamsIntoUrl } from '@utils/ulr';
import { filterFalsyValues } from '@utils/common';
import { getPixelValue } from '@utils/pixel';
import { isIos } from '@utils/browser';

function DownloadAppButton() {
  const { userLocation } = useQuizState();

  const { paymentConfig } = usePaymentState();

  const { user } = useUser();

  function handleClick() {
    const token = user?.isAppleAuth ? user?.providerDataId ?? user?.uid : user?.uid;

    const attribution = getAttribution({
      ip: userLocation?.ip ?? '',
      country: userLocation?.country ?? '',
    });

    const link = mergeSearchParamsIntoUrl(
      isIos()
        ? paymentConfig?.download?.ios ?? APP_CONSTANTS.IOS_DOWNLOAD_APP_LINK
        : paymentConfig?.download?.android ?? APP_CONSTANTS.ANDROID_DOWNLOAD_APP_LINK,
      filterFalsyValues({
        af_ad_id: attribution?.ad_id,
        af_adset_id: attribution?.adset_id,
        af_c_id: attribution?.campaign_id,
        af_ad: attribution?.ad_name,
        af_adset: attribution?.adset_name,
        c: attribution?.campaign_name,
        campaign: attribution?.campaign_name,
        af_sub1: paymentConfig?.pixel ? getPixelValue(paymentConfig?.pixel) : '',
        af_sub2: attribution?.fbc,
        af_sub3: attribution?.fbp,
        pid: 'quiz',
        af_sub4: token,
        af_sub5: user?.providerId?.slice(0, user?.providerId?.indexOf('.')),
      })
    );

    window.open(link, '_blank');
  }

  return <QuizButton text="Download app" variant={ButtonType.WHITE} onClick={handleClick} />;
}

export default DownloadAppButton;
