import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo_huge.svg';
import StartTheQuizButton from './common/StartTheQuizButton';
import TermsAndConditions from './common/TermsAndConditions';
import DownloadAppButton from './common/DownloadAppButton';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#112417',
        padding: '60px 20px 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <img width={75} src={logo} alt="logo"></img>

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir600',
          fontSize: '24px',
          color: '#FFF',
          lineHeight: 'normal',
        }}
      >
        The time is now to transform your relationship with alcohol for good
      </Typography>
      <StartTheQuizButton />

      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir400',
          fontSize: '16px',
          color: '#728879',
          lineHeight: 'normal',
        }}
      >
        Complete a brief questionnaire so we can create a personalized plan tailored to you
      </Typography>
      <Box
        sx={{
          height: '1px',
          width: '100%',
          background: '#1E3927',
          margin: '40px 0 20px',
        }}
      ></Box>
      <TermsAndConditions isMainScreen />
      <DownloadAppButton />
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Avenir400',
          fontSize: '14px',
          color: '#728879',
          lineHeight: 'normal',
        }}
      >
        Refuse by BE-WILL LIMITED © 2024 | Room 1002, 10/F, Easey Commercial Building, 253-261 Hennessy Road, Wan Chai,
        HK
      </Typography>
    </Box>
  );
}

export default Footer;
