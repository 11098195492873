import { Box, Typography } from '@mui/material';
import bg from '../../../assets/images/main/intro_background_mob.webp';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useNavigate } from 'react-router-dom';
import { getPathWithSearchParams } from '@utils/ulr';
import { Routes } from '../../../App';
import StartTheQuizButton from './common/StartTheQuizButton';

function Hero() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: '96px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Avenir500',
          fontSize: '16px',
          textAlign: 'center',
          userSelect: 'none',
          color: '#ED744E',
        }}
      >
        Ready for a Transformation?
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Avenir600',
          fontSize: '32px',
          color: '#282624',
          lineHeight: 'normal',
          textAlign: 'center',
          userSelect: 'none',
          '.orange': {
            color: '#ED744E',
          },
        }}
      >
        Redefine Your
        <br />
        Relationship with
        <br />
        <span className="orange"> Alcohol</span>
      </Typography>
      <Box
        sx={{
          width: '218px',
        }}
      >
        <StartTheQuizButton />
      </Box>
      <Box
        sx={{
          height: '425px',
          marginTop: '140px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          id="reviewsAnchor"
          sx={{
            scrollMargin: '84px',
            fontFamily: 'Avenir600',
            fontSize: '24px',
            textAlign: 'center',
            userSelect: 'none',
            color: '#FFF',
            margin: '0 20px',
          }}
        >
          Join the thousands of people we have helped to break bad habits
        </Typography>
      </Box>
    </Box>
  );
}

export default Hero;
