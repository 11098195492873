function detectBrowserByUserAgent(matchString: string): boolean {
  return window.navigator.userAgent.includes(matchString);
}

export const isInstagram: boolean = detectBrowserByUserAgent('Instagram');

export const isFacebook: boolean =
  detectBrowserByUserAgent('FBAV') || detectBrowserByUserAgent('FBAN');

export const isMetaInAppBrowser: boolean = isFacebook || isInstagram;

export function isIos() {
  return /iphone|ipad|ipod|mac|macintosh|ios/gi.test(
    window.navigator.userAgent.toLowerCase()
  );
}
