import { Box, Typography } from '@mui/material';
import logo from '../../../assets/images/main/logo_new.svg';
import menu from '../../../assets/images/main/menu.svg';
import cross from '../../../assets/images/main/cross.svg';
import { useEffect, useState } from 'react';
import StartTheQuizButton from './common/StartTheQuizButton';
import DownloadAppButton from './common/DownloadAppButton';
import TermsAndConditions from './common/TermsAndConditions';

function Header({ isFull }: { isFull: boolean }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isMenuOpen]);

  const handleClick = (target: string) => {
    setMenuOpen(false);
    const targetDiv = document.getElementById(target);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#FCF4EF',
          width: '100%',
          height: isFull ? '84px' : '48px',
          padding: '0 20px',
          position: 'fixed',
          maxWidth: '460px',
          top: 0,
          transition: 'all 0.5s ease',
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <img
            width={isFull ? '138px' : '92px'}
            height={isFull ? '42px' : '28px'}
            style={{
              transition: 'all 0.5s ease',
            }}
            src={logo}
            alt="logo"
          ></img>
          <img
            onClick={() => setMenuOpen((prev) => !prev)}
            width={'28px'}
            height={'22px'}
            src={isMenuOpen ? cross : menu}
            alt="menu"
          ></img>
        </Box>
      </Box>
      {isMenuOpen && (
        <Box
          sx={{
            background: '#FCF4EF',
            position: 'fixed',
            top: isFull ? '84px' : '48px',
            bottom: 0,
            left: 0,
            transition: 'all 0.5s ease',
            right: 0,
            zIndex: 999,
            borderTop: '1px solid #F9E9E0',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              padding: '30px 20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                borderBottom: '1px solid #F0CCB9',
                paddingBottom: '30px',
                '& p': {
                  fontFamily: 'Avenir600',
                  fontSize: '24px',
                  color: '#282624',
                  lineHeight: 'normal',
                  userSelect: 'none',
                },
              }}
            >
              <Typography onClick={() => handleClick('reviewsAnchor')}>Our reviews</Typography>
              <Typography onClick={() => handleClick('programAnchor')}>Personalized Program</Typography>
              <Typography onClick={() => handleClick('whatYouGetAnchor')}>What do you get?</Typography>
            </Box>
            <Box
              sx={{
                padding: '30px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <StartTheQuizButton />
              <DownloadAppButton />
            </Box>
            <Box>
              <TermsAndConditions />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Header;
