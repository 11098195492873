import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { trackEvent } from '@api/requests';

import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks';
import Header from './components/Header';
import Reviews from './components/Reviews';
import Hero from './components/Hero';
import Program from './components/Program';
import WhatYouGet from './components/WhatYouGet';
import Footer from './components/Footer';

function Main() {
  const { userLocation } = useQuizState();

  useEffect(() => {
    trackEvent(`Intro shown`, userLocation);
  }, []);

  const [isFull, setIsFull] = useState(true);

  useEffect(() => {
    const scroll = () => {
      setIsFull(window.scrollY === 0);
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#FCF4EF',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '460px',
        }}
      >
        <Header isFull={isFull} />
        <Hero />
        <Reviews />
        <Program />
        <WhatYouGet />
        <Footer />
      </Box>
    </Box>
  );
}

export default Main;
